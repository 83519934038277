import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import SEO from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout"
import Header from "../components/header"

class newsPost extends Component {
  render() {
    const news = this.props.data.wpPost.news
    const posts = this.props.data.post
    const prev = this.props.data.prev
    const next = this.props.data.next

    return (
      <Layout>
        <SEO title={news.newsTitleCn} />
        <Header activePage="news" color={{ bg: "transparent", text: "#000" }} />
        <div className="js-text-animation" hidden>
          text hide
        </div>
        <div
          className="banner js-viewport-animation"
          data-bg-color="transparent"
          data-color="#000"
        >
          <GatsbyImage
            image={this.props.data.banner?.childImageSharp?.gatsbyImageData}
            alt=""
            loading="eager" />
          <div className="post-ban-txt wp1000">
            <div
              className="cn-txt animate-sweet-in"
              dangerouslySetInnerHTML={{ __html: news.newsTitleCn }}
            ></div>
            <div
              className="en-txt animate-sweet-in"
              dangerouslySetInnerHTML={{ __html: news.newsTitleEn }}
            ></div>
          </div>
        </div>
        <div
          className="section post-detail js-viewport-animation"
          data-bg-color="#000"
          data-color="#fff"
        >
          <div className="wp1180">
            <div className="newsCover">
              {news.newsCover ? (
                <GatsbyImage image={news.newsCover?.localFile?.childImageSharp?.gatsbyImageData} alt="" />
              ) : (
                <GatsbyImage image={data.default?.childImageSharp?.gatsbyImageData} alt="" />
              )}
            </div>
            <div className="wp1000">
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: posts.content }}
              ></div>

              <div
                className={`post-pages pages${
                  prev.databaseId == posts.databaseId ? " noprev" : ""
                }${next.databaseId == posts.databaseId ? " nonext" : ""}`}
              >
                <Link to={`/news/${prev.slug}/`} className="prev">
                  <span>prev</span>
                </Link>
                <Link to={`/news/${next.slug}/`} className="next">
                  <span>next</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

newsPost.propTypes = {
  data: PropTypes.object.isRequired,
}

export default newsPost

export const postQuery = graphql`query ($databaseId: Int!, $prev: Int, $next: Int) {
  banner: file(relativePath: {eq: "post-banner.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
  default: file(relativePath: {eq: "default_works.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 720, quality: 80, layout: CONSTRAINED)
    }
  }
  wpPost(databaseId: {eq: $databaseId}) {
    news {
      newsTitleCn
      newsTitleEn
      newsCover {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 80, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
  post: wpPost(databaseId: {eq: $databaseId}) {
    content
    databaseId
  }
  prev: wpPost(databaseId: {eq: $prev}) {
    databaseId
    slug
  }
  next: wpPost(databaseId: {eq: $next}) {
    databaseId
    slug
  }
}
`
